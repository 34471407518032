<!-- Author: Wilson Pilco Nunez -->
<!-- Email: wilsonaux1@gmail.com -->
<!-- Created at: 2024-10-09 15:30 -->
<!-- Description:  -->
<template>
  <table class="table table-bordered table-sm">
    <thead>
      <tr>
        <th colspan="2" class="text-center">RESUMEN</th>
      </tr>
      <tr>
        <th>M. Pago</th>
        <th>Monto</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(l, i) in list" :key="i">
        <td>{{ l.pay_method }}</td>
        <td class="text-right text-nowrap">
          <app-span-money :quantity="l.amount_paid" moneyCode="1">
          </app-span-money>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import _ from "lodash";
// script content
export default {
  name: "TableResumePayMethod",
  components: {
    //
  },

  // directives

  props: {
    //
  },

  data: () => ({
    list: [],
  }),

  computed: {
    //
  },

  // watch: {},

  mounted() {
    //
  },

  methods: {
    reset() {
      this.list = [];
    },
    generate(data) {
      let list = [];
      let groups = _.groupBy(data, "payment_method_id");
      for(let keyIt in groups) {
        // console.log("gr", groups[keyIt]);
        list.push({
          pay_method: groups[keyIt][0].pay_method,
          amount_paid: groups[keyIt].reduce(function(p, c) {
            return p + parseFloat(c.amount_paid);
          }, 0),
        });
      }
      this.list = list;
    },
  }
};
</script>
<style scoped></style>

